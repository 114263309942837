import React from 'react';
import ContentBlock from '../content-block';

const NotFoundContent = () => (
  <ContentBlock title="404">
    <div className="col-md-12">
      <p className="text-muted">
        This page cannot be found
      </p>
      <p>
        Check if the URL of the page is correct or go back to the <a href="/">home page</a>.
      </p>
      <p>
        If you keep having this issue get in touch to tell us about the problem so we can fix it!
      </p>
    </div>
  </ContentBlock>
)

export default NotFoundContent;
