import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import NotFoundContent from "../components/notfoundpage/notfound-content";

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="Page not found"
      keywords={["CuriousBits", "Not Found", "404"]}
    />
    <NotFoundContent/>
  </Layout>
);

export default NotFoundPage;
